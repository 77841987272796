const hamburger = document.querySelector('.hamburger');
const cookiePopup = document.querySelector('.cookies-popup');
const cookiePopupBtn = document.querySelector('.cookies-popup__btn');
const bottomHeader = document.querySelector('.header__bottom');
const content = document.querySelector('.content');
const contact = document.querySelector('.contact');
const office = document.querySelector('.office');

AOS.init({
  duration: 1000,
  once: true,
});

hamburger.addEventListener('click', () => {
  hamburger.classList.toggle('is-active')
  bottomHeader.classList.toggle('header__bottom--opened')
})

const goToTopIcon = document.querySelector('.go-to-top');

goToTopIcon.addEventListener('click', () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})

let prevScroll = window.scrollY || document.documentElement.scrollTop;
let prevDirection = 0;
let direction = 0;

let curScroll;
const header = document.querySelector('.header__top');
const headerBottom = document.querySelector('.header__bottom');

window.onscroll = () => {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    goToTopIcon.style = 'bottom: 20px';
  } else {
    goToTopIcon.style = 'bottom: -40px';
  }

  if (document.documentElement.clientWidth > 991) {
    curScroll = window.scrollY || document.documentElement.scrollTop;
    if (curScroll > prevScroll) {
      direction = 2;
    } else if (curScroll < prevScroll) {
      direction = 1;
    }


    if (direction !== prevDirection) {
      if (direction === 2 && curScroll > 52) {
        header.classList.add('hide');
        headerBottom.classList.add('hide');
        prevDirection = direction;
      } else if (direction === 1) {
        header.classList.remove('hide');
        headerBottom.classList.remove('hide');
        prevDirection = direction;
      }
    }

    prevScroll = curScroll;
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const elements = document.querySelectorAll(".scroll-counter")

  elements.forEach(function (item) {
    item.counterAlreadyFired = false
    item.counterSpeed = item.getAttribute("data-counter-time") / 45
    item.counterTarget = +item.innerText
    item.counterCount = 0
    item.counterStep = item.counterTarget / item.counterSpeed

    item.updateCounter = function () {
      item.counterCount = item.counterCount + item.counterStep
      item.innerText = Math.ceil(item.counterCount)

      if (item.counterCount < item.counterTarget) {
        setTimeout(item.updateCounter, item.counterSpeed)
      } else {
        item.innerText = item.counterTarget
      }
    }
  })

  // Function to determine if an element is visible in the web page
  const isElementVisible = function isElementVisible(el) {
    const scroll = window.scrollY || window.pageYOffset
    const boundsTop = el.getBoundingClientRect().top + scroll + 70
    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
    }
    const bounds = {
      top: boundsTop,
      bottom: boundsTop + el.clientHeight,
    }
    return (
      (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
      (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
    )
  }

  const handleScroll = function handleScroll() {
    elements.forEach(function (item, id) {
      if (true === item.counterAlreadyFired) return
      if (!isElementVisible(item)) return
      item.updateCounter()
      item.counterAlreadyFired = true
    })
  }

  window.addEventListener("scroll", handleScroll)
});

(() => {
  if (!getCookie("accept-cookies")) {
    cookiePopup.classList.remove('d-none');
  }
})();

cookiePopupBtn.addEventListener('click', () => {
  cookiePopup.classList.add('d-none');
  setCookie("accept-cookies", "true", 7);
})

function setCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  })
  return res;
}
